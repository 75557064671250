@import '../../../general/scss/settings/variables.scss';
@import '../../../general/scss/settings/mixins.scss';

.section-header-bar {
    padding: 1rem 1rem 1.25rem;

    h1,
    h2 {
        padding-bottom: 0 !important;
        margin-bottom: 0.375rem !important;
        margin-top: 0.375rem !important;
        font-size: 1.125rem;
    }

    &.dk-blue {
        background-color: $unipol-dark-blue;

        h1,
        h2 {
            color: $white;
        }

        .btn-outline {
            &:hover {
                .material-icons {
                    color: $unipol-dark-blue !important;
                }
            }
        }
    }

    &.white {
        background-color: $white;
    }
}

@include media-breakpoint-up(md) {
    .section-header-bar {
        padding: 1rem;

        h1 {
            margin-top: 0.75rem !important;
            font-size: 2rem;
        }

        h2 {
            margin-top: 0.5rem !important;
            font-size: 1.75rem;
        }
    }
}

.styled-date {
    @include gradientBackground();
    border-radius: rem-calc(70);
    overflow: hidden;
    padding: rem-calc(6);
    text-align: center;
    margin: 1.5rem auto 1rem;
    height: rem-calc(140);
    width: rem-calc(140);

    .styled-date-inner {
        color: $unipol-green;
        background-color: $white;
        border-radius: rem-calc(64);
        overflow: hidden;
        text-transform: uppercase;
        height: rem-calc(128);
        width: rem-calc(128);
        position: absolute;
        left: rem-calc(6);
        top: rem-calc(6);
    }

    .styled-date-day-text {
        display: block;
        font-size: 1.15rem;
        font-weight: 500;
        line-height: 1;
        margin-top: 1rem;
    }

    .styled-date-day {
        display: block;
        font-size: 3.5rem;
        font-weight: 500;
        line-height: 1;
    }

    .styled-date-month {
        display: block;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1;
    }
}

.styled-photo {
    @include gradientBackground();
    border-radius: rem-calc(60);
    overflow: hidden;
    padding: rem-calc(6);
    text-align: center;
    margin: 0 auto;
    height: rem-calc(120);
    width: rem-calc(120);

    .styled-photo-inner {
        color: $unipol-green;
        background-color: $white;
        border-radius: rem-calc(54);
        overflow: hidden;
        text-transform: uppercase;
        height: rem-calc(108);
        width: rem-calc(108);
        position: absolute;
        left: rem-calc(6);
        top: rem-calc(6);

        img {
            width: auto !important;
            margin-top: 50%;
            transform: translateY(-50%);
        }
    }
}

.search-panel {
    background-color: $unipol-light-blue;
    color: $white;
    padding: rem-calc(12);

    h2 {
        color: $white;
        font-size: rem-calc(30);
        font-weight: $font-weight-light;
        text-align: center;
    }

    .advert-count {
        padding-bottom: rem-calc(20);
        text-align: center;

        span {
            display: block;
        }

        &-number {
            font-size: rem-calc(60);
            line-height: 1;
        }
    }

    .rotating-item-wrapper {
        position: relative;
        height: 116px;
    }

    .rotating-item {
        /*display: none;*/
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}

@include media-breakpoint-up(lg) {
    .search-panel {
        @include anglePanelSide($position: right);
        padding: rem-calc(18) rem-calc(28);
        height: 100%;

        .advert-count {
            padding-bottom: rem-calc(10);

            &-number {
                font-size: rem-calc(48);
            }
        }

        .rotating-item-wrapper {
            height: 90px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .search-panel {
        padding: rem-calc(34) rem-calc(44);

        h2 {
            font-size: rem-calc(36);
        }

        .advert-count {
            &-number {
                font-size: rem-calc(74);
            }
        }

        .rotating-item-wrapper {
            height: 116px;
        }
    }
}

.simple-page-panel {
    padding-bottom: 1rem;
    padding-top: 1rem;

    .section-inner {
        background-color: $white;
        padding: 1rem;
    }
}

@include media-breakpoint-up(md) {
    .simple-page-panel {
        padding-bottom: 1rem;
        padding-top: 1rem;

        .section-inner {
            padding: 2rem;
        }
    }
}

@include media-breakpoint-up(lg) {
    .simple-page-panel {
        padding-bottom: 2rem;
        padding-top: 2rem;

        .section-inner {
            padding: 3rem 5.75rem;
        }
    }
}

.white-panel,
aside .well,
.need-any-help-container {
    background-color: $white;
    padding: 1rem;
}

@include media-breakpoint-up(lg) {
    .white-panel,
    aside .well,
    .need-any-help-container {
        padding: 2rem;
    }
}

.green-panel {
    background-color: $unipol-green;
    color: $white;
}

.filter-bar {
    background-color: $white;
    font-weight: $font-weight-semibold;

    .col {
        padding: 0.25rem 1rem;
    }
}

.related-articles-panel {
}

.need-any-help-panel {
}

.property-image-container {
}

@include media-breakpoint-up(md) {
    .property-image-container {
        max-height: 300px;
        overflow: hidden;
    }
}

.sidebar-panel {
    background-color: $white;
    padding: 1rem;

    @media (min-width: 992px) {
        padding: 2rem;
    }

    .sidebar-panel__title {
    }
    .sidebar-panel__body {
    }
    .sidebar-panel__warning{
        visibility: visible;
    }
    .sidebar-panel__warning--hide{
        visibility: hidden;
    }
    ul>li>a{
        overflow-wrap: break-word;
    }
}

.city-filter-dropdown {
    background-color: $unipol-off-white;
    font-weight: $font-weight-light;
    padding: .5rem 1rem;
    width: 100%;
}